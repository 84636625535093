
import * as Sentry from '@sentry/vue'
import { Component, Prop } from 'vue-property-decorator'
import { LootSolverData } from '@/interfaces/loot_solver'
import SavageAimMixin from '@/mixins/savage_aim_mixin'
import Tier from '@/interfaces/tier'
import LootSolverDistributionTable from '@/components/modals/loot_solver_distribution.vue'

@Component
export default class LootSolver extends SavageAimMixin {
  loaded = false

  data!: LootSolverData

  isShown = false

  @Prop()
  lootManagerType!: string

  @Prop()
  teamMemberNames!: { [id: number]: string }

  @Prop()
  tier!: Tier

  @Prop()
  url!: string

  @Prop()
  userHasPermission!: boolean

  get shouldShowAssignButton(): boolean {
    return this.userHasPermission && this.lootManagerType === 'fight'
  }

  autoAssignFirstFloor(): void {
    this.$emit('auto-assign-first-floor', this.data.first_floor[0])
  }

  autoAssignSecondFloor(): void {
    this.$emit('auto-assign-second-floor', this.data.second_floor[0])
  }

  autoAssignThirdFloor(): void {
    this.$emit('auto-assign-third-floor', this.data.third_floor[0])
  }

  async fetchData(reload: boolean): Promise<void> {
    // Load the solver data from the API
    this.loaded = false
    try {
      // Pick a URL at random, 50% odds each time
      const response = await fetch(this.url)

      if (response.ok) {
        // Parse the JSON and save it in instance variables
        this.data = (await response.json()) as LootSolverData
        this.loaded = true
        if (reload) this.$forceUpdate()
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Loot Solver Data.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  mounted(): void {
    this.fetchData(false)
  }

  showFirstFloorDistribution(): void {
    this.$modal.show(LootSolverDistributionTable, { data: this.data.first_floor, fight: this.tier.fights[0], teamMemberNames: this.teamMemberNames })
  }

  showSecondFloorDistribution(): void {
    this.$modal.show(LootSolverDistributionTable, { data: this.data.second_floor, fight: this.tier.fights[1], teamMemberNames: this.teamMemberNames })
  }

  showThirdFloorDistribution(): void {
    this.$modal.show(LootSolverDistributionTable, { data: this.data.third_floor, fight: this.tier.fights[2], teamMemberNames: this.teamMemberNames })
  }

  // Hide / Show the History body
  toggleShown(): void {
    this.isShown = !this.isShown
  }
}
