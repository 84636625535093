
import * as Sentry from '@sentry/vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Loot } from '@/interfaces/loot'
import Team from '@/interfaces/team'

@Component
export default class DeleteLoot extends Vue {
  @Prop()
  items!: Loot[]

  @Prop()
  team!: Team

  get url(): string {
    return `/backend/api/team/${this.team.id}/loot/delete/`
  }

  async deleteLoot(): Promise<void> {
    const body = JSON.stringify({ items: this.items.map((item: Loot) => item.id) })
    try {
      const response = await fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': Vue.$cookies.get('csrftoken'),
        },
        body,
      })

      if (response.ok) {
        // Attempt to parse the json, get the id, and then redirect
        this.$emit('close')
        this.$notify({ text: 'Loot History entries deleted successfully!', type: 'is-success' })
      }
      else {
        this.$notify({ text: `Unexpected response ${response.status} when attempting to delete Loot History.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to delete Loot History.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }
}
