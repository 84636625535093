
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ItemDropdown extends Vue {
  @Prop()
  error!: string[] | undefined

  @Prop()
  value!: number

  get dropdown(): HTMLSelectElement {
    return this.$refs.dropdown as HTMLSelectElement
  }

  handleInput(): void {
    this.$emit('input', this.dropdown.value)
  }
}
