
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TomeGreedGear, GreedItem } from '@/interfaces/loot'

@Component
export default class GreedTomeModal extends Vue {
  @Prop()
  entry!: TomeGreedGear

  @Prop()
  save!: (list: GreedItem) => void

  select(list: GreedItem): void {
    this.save(list)
    this.$emit('close')
  }
}
