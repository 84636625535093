
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TomeNeedGear } from '@/interfaces/loot'

@Component
export default class NeedTomeItem extends Vue {
  @Prop()
  entry!: TomeNeedGear

  @Prop()
  itemsReceived!: number

  @Prop()
  requesting!: boolean

  @Prop()
  userHasPermission!: boolean

  save(): void {
    this.$emit('save')
  }
}
