
import { Component, Prop, Vue } from 'vue-property-decorator'
import NeedRaidItemBox from '@/components/loot/need_raid_item_box.vue'
import NeedTomeItemBox from '@/components/loot/need_tome_item_box.vue'
import {
  GreedGear,
  GreedItem,
  LootReceived,
  NeedGear,
  PerFightChosenMember,
  TomeGreedGear,
  TomeGreedItem,
  TomeNeedGear,
} from '@/interfaces/loot'

@Component({
  components: {
    NeedRaidItemBox,
    NeedTomeItemBox,
  },
})
export default class PerFightMemberSelect extends Vue {
  @Prop()
  choose!: (data: PerFightChosenMember, item: string) => void

  @Prop()
  greed!: GreedGear[] | TomeGreedGear[]

  greedDropdowns: { [id: number]: boolean } = {}

  @Prop()
  item!: string

  @Prop()
  need!: NeedGear[] | TomeNeedGear[]

  @Prop()
  received!: LootReceived

  tabs = {
    showNeed: true,
    showGreed: false,
  }

  chooseGreed(data: GreedGear | TomeGreedGear, list: GreedItem | TomeGreedItem | null): void {
    const neededData = {
      greed: true,
      greed_list_id: list?.bis_list_id || null,
      items_received: this.getGreedReceived(data),
      member_id: data.member_id,
      member_name: data.character_name,
      job_id: list?.job_icon_name || null,
    }
    this.handleChoose(neededData)
  }

  chooseNeed(data: NeedGear | TomeNeedGear): void {
    const neededData = {
      greed: false,
      greed_list_id: null,
      items_received: this.getNeedReceived(data),
      member_id: data.member_id,
      member_name: data.character_name,
      job_id: data.job_icon_name,
    }
    this.handleChoose(neededData)
  }

  get showRequires(): boolean {
    return (this.item === 'Tome Armour Augment' || this.item === 'Tome Accessory Augment')
  }

  getGreedReceived(entry: GreedGear | TomeGreedGear): number {
    // Given an entry, return how many times that Character has received greed loot so far this tier
    return this.received[entry.character_name]?.greed || 0
  }

  getNeedReceived(entry: NeedGear | TomeNeedGear): number {
    // Given an entry, return how many times that Character has received need loot so far this tier
    return this.received[entry.character_name]?.need || 0
  }

  handleChoose(details: PerFightChosenMember): void {
    this.choose(details, this.item)
    this.$emit('close')
  }

  showGreed(): void {
    this.tabs = { showNeed: false, showGreed: true }
  }

  showNeed(): void {
    this.tabs = { showNeed: true, showGreed: false }
  }

  toggleGreedDropdown(memberId: number): void {
    const prev = this.greedDropdowns[memberId] || false
    this.greedDropdowns[memberId] = !prev
    this.$forceUpdate()
  }
}
