
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LootSolverDistributionTable extends Vue {
  @Prop()
  data!: { [item: string]: number }[]

  @Prop()
  fight!: string

  @Prop()
  teamMemberNames!: { [id: number]: string }

  get headers(): string[] {
    // Can't display the Modal if data.length == 0 so this is safe
    const dataKeys = Object.keys(this.data[0])
    if (dataKeys.includes('Earrings')) return ['Earrings', 'Necklace', 'Bracelet', 'Ring']
    if (dataKeys.includes('Head')) return ['Head', 'Hands', 'Feet', 'Tome Accessory Augment']
    return ['Body', 'Legs', 'Tome Armour Augment']
  }
}
