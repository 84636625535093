
import { Component, Prop } from 'vue-property-decorator'
import DeleteLoot from '@/components/modals/confirmations/delete_loot.vue'
import ItemDropdown from '@/components/item_dropdown.vue'
import {
  Loot,
  LootData,
  LootPacket,
} from '@/interfaces/loot'
import { LootCreateErrors } from '@/interfaces/responses'
import Team from '@/interfaces/team'
import SavageAimMixin from '@/mixins/savage_aim_mixin'

@Component({
  components: {
    ItemDropdown,
  },
})
export default class History extends SavageAimMixin {
  createData = {
    item: 'na',
    member: -1,
    obtained: '',
  }

  errors: LootCreateErrors = {}

  @Prop()
  fetchData!: (reload: boolean) => Promise<void>

  historyShown = false

  @Prop()
  loot!: LootData

  @Prop()
  sendLoot!: (data: LootPacket) => Promise<LootCreateErrors | null>

  @Prop()
  requesting!: boolean

  @Prop()
  team!: Team

  @Prop()
  url!: string

  @Prop()
  userHasPermission!: boolean

  deleteEntries(items: Loot[]): void {
    // Prompt deletion first before sending an api request (we'll use a modal instead of javascript alerts)
    this.$modal.show(DeleteLoot, { team: this.team, items }, { }, {
      closed: () => {
        this.fetchData(true)
        this.$emit('reload-solver')
      },
    })
  }

  deleteMultipleEntries(): void {
    const checkboxes = this.$refs.lootDeleteCheckbox as HTMLInputElement[]
    const ids = checkboxes.filter((check: HTMLInputElement) => check.checked).map((check: HTMLInputElement) => parseInt(check.dataset.id!, 10)) as number[]
    const items = this.loot.history.filter((entry: Loot) => ids.includes(entry.id))
    this.deleteEntries(items)
  }

  // Hide / Show the History body
  toggleHistory(): void {
    const icon = this.$refs.historyIcon as Element
    const history = this.$refs.history as Element
    if (this.historyShown) {
      this.historyShown = false
      icon.innerHTML = 'expand_more'
    }
    else {
      this.historyShown = true
      icon.innerHTML = 'expand_less'
    }
    history.classList.toggle('is-hidden')
  }

  async trackExtraLoot(greed: boolean): Promise<void> {
    this.errors = {}
    if (this.createData.obtained === '') {
      this.errors.obtained = ['Please enter a date.']
      return
    }
    const data = {
      member_id: this.createData.member,
      obtained: this.createData.obtained,
      item: this.createData.item,
      greed,
    }
    const response = await this.sendLoot(data)
    if (response === null) {
      this.createData = { item: 'na', member: -1, obtained: '' }
      this.$emit('reload-solver')
    }
    else {
      this.errors = response
    }
  }
}
