
import dayjs from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import GreedCharacterEntry from '@/components/loot/greed_character_entry.vue'
import History from '@/components/loot/history.vue'
import ItemDropdown from '@/components/item_dropdown.vue'
import NeedRaidItemBox from '@/components/loot/need_raid_item_box.vue'
import NeedTomeItemBox from '@/components/loot/need_tome_item_box.vue'
import {
  GreedGear,
  GreedItem,
  NeedGear,
  LootData,
  LootPacket,
  LootWithBISPacket,
  TomeGreedGear,
  TomeNeedGear,
} from '@/interfaces/loot'
import { LootCreateErrors, LootBISCreateErrors } from '@/interfaces/responses'

@Component({
  components: {
    GreedCharacterEntry,
    History,
    ItemDropdown,
    NeedRaidItemBox,
    NeedTomeItemBox,
  },
})
export default class PerItemLootManager extends Vue {
  errors: LootBISCreateErrors = {}

  displayItem = 'na'

  @Prop()
  loot!: LootData

  @Prop()
  requesting!: boolean

  @Prop()
  sendLoot!: (data: LootPacket) => Promise<LootCreateErrors | null>

  @Prop()
  sendLootWithBis!: (data: LootWithBISPacket) => Promise<LootBISCreateErrors | null>

  @Prop()
  userHasPermission!: boolean

  getGreedReceived(entry: GreedGear): number {
    // Given an entry, return how many times that Character has received greed loot so far this tier
    return this.loot.received[entry.character_name]?.greed || 0
  }

  getNeedReceived(entry: NeedGear): number {
    // Given an entry, return how many times that Character has received need loot so far this tier
    return this.loot.received[entry.character_name]?.need || 0
  }

  // Functions to handle interacting with the API for handling loot handouts
  giveGreedRaidLoot(entry: GreedGear, list: GreedItem): void {
    const data = {
      greed: true,
      greed_bis_id: list.bis_list_id,
      member_id: entry.member_id,
      item: this.displayItem,
    }
    this.trackBisLoot(data)
  }

  // Tome loot sends information using the non bis api -> tracks history, no BIS updates
  giveGreedTomeLoot(entry: TomeGreedGear): void {
    const data = {
      greed: true,
      obtained: dayjs().format('YYYY-MM-DD'),
      member_id: entry.member_id,
      item: this.displayItem,
    }
    this.sendLoot(data)
  }

  // Determine what function to call for a given item
  giveNeedLoot(entry: NeedGear | TomeNeedGear): void {
    if (this.displayItem.indexOf('tome') !== -1 || this.displayItem === 'mount') {
      // We cannot run an update but we do want to save them
      this.giveNeedTomeLoot(entry as TomeNeedGear)
    }
    else {
      // Anything else can be updated since it's all raid gear
      this.giveNeedRaidLoot(entry as NeedGear)
    }
  }

  giveNeedRaidLoot(entry: NeedGear): void {
    const data = {
      greed: false,
      greed_bis_id: null,
      member_id: entry.member_id,
      item: this.displayItem,
    }
    this.trackBisLoot(data)
  }

  giveNeedTomeLoot(entry: TomeNeedGear): void {
    const data = {
      greed: false,
      obtained: dayjs().format('YYYY-MM-DD'),
      member_id: entry.member_id,
      item: this.displayItem,
    }
    this.sendLoot(data)
  }

  // Helper function that sits inbetween the giveRaidLoot and sendLootWithBis functions
  async trackBisLoot(data: LootWithBISPacket): Promise<void> {
    this.errors = {}
    const response = await this.sendLootWithBis(data)
    if (response !== null) {
      this.errors = response
    }
  }
}
